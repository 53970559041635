import React, { useState } from 'react'

import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import { ReactComponent as BeginnerIcon } from 'images/icons/beginner.svg'
import { ReactComponent as ConnectIcon } from 'images/icons/connect.svg'
import Popup from "./Popup";

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  gridList: {
    display: 'flex',
    [breakpoints.down('xs')]: {
      padding: spacing(0, 3),
    },
  },
  button: {
    flex: 1,
    boxShadow: '0 0 4px 0 rgba(0,0,0,0.5)',
    borderRadius: 2,
    [breakpoints.down('xs')]: {
      padding: spacing(4),
    },
    [breakpoints.up('sm')]: {
      padding: spacing(9, 6),
    },
    '& .MuiButton-label': {
      display: 'flex',
      alignItems: 'center',
      [breakpoints.down('xs')]: {
        flexDirection: 'column',
        justifyContent: 'start',
      },
      [breakpoints.up('sm')]: {},
    },
  },
  text: {
    fontWeight: 'bold',
    color: '#fff',
    [breakpoints.down('xs')]: {
      marginTop: spacing(3),
    },
    [breakpoints.up('sm')]: {
      marginLeft: spacing(3),
    },
  },
  space: {
    width: spacing(3),
  },
}))
export default function Guide({ style }) {
  const [connectMessage, setConnectMessage] = useState(false)
  const classes = useStyles()
  const Grid = ({ icon, color, children, onClick }) => (
    <Button onClick={onClick} className={classes.button} style={{ background: color }}>
      {icon}
      <Typography variant="body1" className={classes.text}>
        {children}
      </Typography>
    </Button>
  )

  return (
    <div className={classes.gridList} style={style}>
      <Grid
        icon={<BeginnerIcon />}
        color="#3ac2a8"
        onClick={() => window.open(process.env.REACT_APP_FOR_BEGINNER_URL)}
      >
        初めてイベントに参加する方へ
      </Grid>
      <div className={classes.space} />
      {!Boolean(process.env.REACT_APP_DATA_UPLOAD_APP_ONLY) && (
        <>
          <div className={classes.space} />
          <Grid icon={<ConnectIcon />} color='#fbaa51' onClick={() => setConnectMessage(true)}>
            計測機器を接続する
          </Grid>
        </>
      )}
      {connectMessage && (
          <Popup
              open
              onClose={() => setConnectMessage(false)}
              title="お知らせ"
              description="2023年12月開催のイベントをもちまして、Webサイトからの完走・完歩証の発行を終了いたしました。計測機器の接続はRuntripアプリより実施してください。"
              primaryText="OK"
              onClickPrimary={() => setConnectMessage(false)}
          />
      )}
    </div>
  )
}
