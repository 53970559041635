import React from 'react'
import { useHistory } from 'react-router-dom'

import { useTheme } from '@material-ui/core/styles'

import AboutUs from 'components/AboutUs'
import Concept from 'components/Concept'
import CustomButton from 'components/CustomButton'
import FAQ from 'components/FAQ'
import SupportList from 'components/SupportList'
import HowItWorks from 'components/HowItWorks'
import MainImage from 'components/MainImage'
import Reward from 'components/Reward'
import EventList from 'pages/EventList'
import NewsList from 'pages/NewsList'
import ButtonBase from '@material-ui/core/ButtonBase'
import Typography from "@material-ui/core/Typography";
import WarningRoundedIcon from "@material-ui/icons/Info";
import MultilineText from "../components/MultilineText";
import Button from "@material-ui/core/Button";

export default function Top() {
  const { palette, spacing } = useTheme()
  const { push } = useHistory()

  const More = ({ text, href }) => (
    <div
      style={{
        marginBottom: spacing(6),
        background: palette.background.paper,
        padding: spacing(3),
      }}
    >
      <CustomButton
        arrow
        onClick={() => {
          if (href.startsWith('/')) {
            push(href)
          } else {
            window.open(href)
          }
        }}
      >
        {text}
      </CustomButton>
    </div>
  )

  return (
    <div style={{ background: palette.background.default }}>
      <MainImage src="/images/main.jpg" alt="トップ" type="top" />
        <div>
            {Boolean(process.env.REACT_APP_ACCOUNT_ID === '1') && (
                <div style={{background: palette.background.paper}}>
                    <div style={{
                        margin: spacing(3, 3),
                        padding: spacing(3),
                        background: "#E6FAFF",
                        border: "1px solid #42B8E4",
                        borderRadius: "8px",
                    }}>
                        <Typography variant="body2">
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div style={{display: 'flex'}}>
                                    <WarningRoundedIcon style={{color: '#42B8E4', marginRight: spacing(3),}}/>
                                </div>
                                <div>
                                    <MultilineText>本イベントはRuntripアプリ（無料）をインストールしてご参加ください。</MultilineText>
                                </div>
                            </div>
                        </Typography>
                    </div>
                    <div style={{
                        margin: spacing(3, 3),
                        padding: spacing(3),
                        background: "#E6FAFF",
                        border: "1px solid #42B8E4",
                        borderRadius: "8px",
                    }}>
                        <Typography variant="body2">
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div style={{display: 'flex'}}>
                                    <WarningRoundedIcon style={{color: '#42B8E4', marginRight: spacing(3),}}/>
                                </div>
                                <div>
                                    <div style={{marginTop: spacing(2)}}>
                                        <MultilineText>Runtripアプリから完走・完歩証が発行できない場合（Runtripアプリをインストールできない場合等）は、こちらのフォームから申請ください。</MultilineText>
                                    </div>
                                    <Button
                                        style={{marginTop: spacing(2), marginLeft: 0, padding: 0}}
                                        color="secondary"
                                        onClick={() => window.open("https://req.qubo.jp/runtrip/form/vrwc")}
                                    >計測データ審査フォーム
                                    </Button>
                                    <div style={{marginTop: spacing(2)}}>
                                        <MultilineText>※ 2023年12月開催のイベントをもちまして、Webサイトからの完走・完歩証の発行を終了いたしました。</MultilineText>
                                    </div>
                                    <Button
                                        style={{marginTop: spacing(2), marginLeft: 0, padding: 0}}
                                        color="secondary"
                                        onClick={() => window.open("https://vrwc.runtrip.jp/news/736")}
                                    >詳細はこちら
                                    </Button>
                                </div>
                            </div>
                        </Typography>
                    </div>
                </div>
            )}
            {Boolean(process.env.REACT_APP_ACCOUNT_ID !== '1') && (
                <div style={{background: palette.background.paper}}>
                    <div style={{
                        margin: spacing(3, 3),
                        padding: spacing(3),
                        background: "#E6FAFF",
                        border: "1px solid #42B8E4",
                        borderRadius: "8px",
                    }}>
                        <Typography variant="body2">
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div style={{display: 'flex'}}>
                                    <WarningRoundedIcon style={{color: '#42B8E4', marginRight: spacing(3),}}/>
                                </div>
                                <div>
                                    <MultilineText>本イベントはRuntripアプリ（無料）をインストールしてご参加ください。</MultilineText>
                                </div>
                            </div>
                        </Typography>
                    </div>
                </div>
            )}
            <div style={{ display: 'flex', justifyContent: 'center', gap: '30px' }}>
                <ButtonBase
                    style={{ marginBottom: spacing(6) }}
                    onClick={() => window.open('https://runtrip-web.onelink.me/Pprx/vrwctop')}
                >
                    <img height={40} src='/images-static/app_dl_ios.png' alt='' />
                </ButtonBase>
                <ButtonBase
                    style={{ marginBottom: spacing(6) }}
                    onClick={() => window.open('https://runtrip-web.onelink.me/Pprx/vrwctop')}
                >
                    <img height={40} src='/images-static/app_dl_android.png' alt='' />
                </ButtonBase>
            </div>
        </div>
      <Concept />
      {Boolean(process.env.REACT_APP_CONCEPT_HREF) && (
        <More text="詳細を見る" href={process.env.REACT_APP_CONCEPT_HREF} />
      )}
      <NewsList limit={3} />
      <More text="お知らせ一覧" href="/news" />
      <HowItWorks />
      <Reward />
      <EventList limit={7} />
      <More text="イベント一覧" href="/events" />
      <FAQ />
      <SupportList />
      <AboutUs />
    </div>
  )
}
